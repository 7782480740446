import { useEffect } from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useParams } from 'react-router-dom';
import CardAvaregeLogged from '../../../componentsLogged/avarege/cardAvarege';
import CardAvarege from '../cardAvaregeIncome';

import './button.css'

const ButtonRegion = ({ actualYear, year }) => {

    const [region, setRegion] = useState([])
    const [param, setParam] = useState('Norte')

    let paramParam = useParams()

    useEffect(() => {
        fetch('https://anuariodareciclagem.eco.br/app/city')
            .then((response) => response.json())
            .then((json) => setRegion(json))
    }, [actualYear])

    const unicRegion = region.map((region) => region.region)

    function unique(value, index, self) {
        return self.indexOf(value) === index;
    }

    const singleRegions = unicRegion.filter(unique).sort()

    const [showResults, setShowResults] = useState(false)

    const HandleClick = (e) => {
        setParam(e.target.value)
        paramParam = param
        setShowResults(true)
    };

    return (
        <>
            <div className="buttonGroup">
                {singleRegions.map((singleRegion, index) =>
                    <ButtonGroup aria-label="Basic example" key={index}>
                        <Button className="button" variant="secondary"
                            value={singleRegion}
                            onClick={HandleClick}>{singleRegion}</Button>
                    </ButtonGroup>
                )}
            </div>
            {!year && showResults && <CardAvarege param={param} actualYear={actualYear} year={year} />}
            {!actualYear && showResults && <CardAvaregeLogged param={param} actualYear={actualYear} year={year}/>}
        </>
    )
}

export default ButtonRegion