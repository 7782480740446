import { useEffect } from "react"
import { useState } from "react"
import Button from "react-bootstrap/esm/Button"
import ButtonGroup from "react-bootstrap/esm/ButtonGroup"
import { getFlag } from "../../components/cooperative/cooperative2"
import './buttonLetter.css'
import Table from 'react-bootstrap/Table';
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"

const ButtonLetter = ({ year }) => {

    const [cooperative, setCooperative] = useState([])
    const [itemSearch, setItemSearch] = useState('')

    useEffect(() => {
        fetch(`https://anuariodareciclagem.eco.br/app/cooperative`)
            .then((response) => response.json())
            .then((json) => setCooperative(json))
            .catch((error) => error)
    }, [year])

    function Unique(value, index, self) {
        return self.indexOf(value) === index
    }

    const newArray = []
    const nameCooperative = cooperative.map((cooperative) => cooperative.name)
    nameCooperative.forEach(element => newArray.push(element.substring(0, 1)))
    const newArray2 = newArray.filter(Unique).sort().splice(1).splice(1).splice(0, 23)

    const [result, setResult] = useState([])

    useEffect(() => {
        fetch(`https://anuariodareciclagem.eco.br/app/cooperative/city/${getFlag() || "SP"}/${year}`)
            .then((response) => response.json())
            .then((json) => setResult(json))
            .catch((error) => console.log(error))
    }, [itemSearch, getFlag(), year])

    const ue = result.map((item) => item.name)
    function HandleClick(e) {
        setItemSearch(e.target.value)
    }
    const arrayCity = []
    ue.forEach(element => {
        if (element[0] === itemSearch) {
            let obj = {}
            obj.state = element
            arrayCity.push(obj)
        }
    })

    function findOcc(arrayCity, key) {
        let arr2 = [];

        arrayCity.forEach((x) => {

            if (arr2.some((val) => { return val[key] == x[key] })) {
                arr2.forEach((k) => {
                    if (k[key] === x[key]) {
                        k["occurrence"]++
                    }
                })
            } else {
                let a = {}
                a[key] = x[key]
                a["occurrence"] = 1
                arr2.push(a);
            }
        })
        return arr2
    }

    let key = "state"
    const cooperativeByCity = findOcc(arrayCity, key)

    return (
        <>
            <div className="letterButton">
                {newArray2.map((newArray2, index) =>
                    <ButtonGroup aria-label="Basic example" key={index}>
                        <Button className="letterButton" variant="secondary" value={newArray2} onClick={HandleClick}>{newArray2}</Button>
                    </ButtonGroup>
                )}
                <div className="cityQuantity">
                    <Row className=" justify-content-md-center">
                        {cooperativeByCity.map((cooperativeByCity, index) =>
                            <>
                                <Col md={4} className="colSearch">
                                    {cooperativeByCity.state}
                                </Col>
                                <Col className="colSearch">
                                    {cooperativeByCity.occurrence}
                                </Col>
                            </>
                        )}
                    </Row>
                </div>
            </div>
        </>
    )
}

export default ButtonLetter