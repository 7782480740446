import React, { useEffect } from 'react'
import { useState } from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import './active_collectors.css'
import { actualYear, countFlag } from '../yearbook/yearbook';
import ButtonRegion from '../filters/buttonRegions/buttonRegion';

const Collerctors = (props) => {

    const [collector, setCollector] = useState([]);
    let count = countFlag;

    useEffect(() => {
        //fetchUrl()
        fetch((`https://anuariodareciclagem.eco.br/app/activecollectors/${actualYear() || props.year}`),
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
        .then((response) => response.json())
        .then((json) => setCollector(json))
        
   
    }, [count, actualYear(), props.year])
    
    const manCollectos = collector.map(collector => collector.man)
    const wommanCollectos = collector.map(collector => collector.woman)

    var entire = 0
    const overallMans = manCollectos.reduce((previousValue, CurrentValue) => previousValue + CurrentValue, entire)
    const overallWomans = wommanCollectos.reduce((previousValue, CurrentValue) => previousValue + CurrentValue, entire)

    const contCollector = collector.filter(item => item.woman !== 0 && item.woman !== null).map((collector) => [collector.man, collector.woman])

    return (
        <>
            <p className='p5'>
                {Intl.NumberFormat().format(overallWomans + overallMans)}
            </p>
            <ButtonRegion year={props.year} state2={props.state}/>
        </>
    )
}

export default Collerctors