import { Routes, Route, Link, } from "react-router-dom";
import Home from "../pages/home";
import PageLogged from "../pages/LoggedPage";
import Signin from "../pages/signin";
import SignUp from "../pages/singnup";
import Registration from "../pages/registration";
import ChangePassword from "../pages/changePassword";

const Private = ({Item}) => {
    const signed = true 
    return signed > 0 ? <Item/> : <Home/>
}
  
  const Rotas = () => {
      return (
          <Routes>
              <Route exact path="/" element={<Home/>}/>
              <Route exact path="/Home" element={<Private Item={PageLogged}/>}/>
              <Route exact path="/Signin" element={<Signin/>}/>
              <Route exact path="/SignUp" element={<SignUp/>}/>
              <Route exact path="/*" element={<Home/>}/>
              <Route exact path="/Registration" element={<Registration/>}/>
              <Route exact path="/UpdateUser" element={<ChangePassword/>}/>
          </Routes>
      )
  }

  export default Rotas