import './avaregeIncome.css'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import { useState } from 'react'
import { useEffect } from 'react'
import ButtonRegion from './buttons/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComputerMouse } from '@fortawesome/free-solid-svg-icons'
import { MapBrazil } from 'react-brazil-map'
import { useParams } from 'react-router-dom'
import image7 from '../../assets/images/image7.png'


const AvaregeIncome = ({ actualYear, year }) => {
    const newActualYear = parseInt(actualYear)
    const newActualYear2 = parseInt(year)

    let [district, setDistrict] = useState('')
    const [averageMonthly, setaverageMonthly] = useState([])
    const [averageMonthlyState, setaverageMonthlyState] = useState([])

    let param = useParams()
    param = district;

    const fecthIncome = () => {
        fetch(('https://anuariodareciclagem.eco.br/app/yearbook'),
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            })
            .then((response) => response.json())
            .then((json) => setaverageMonthly(json))
            .catch((err) => console.log(err))
    }

    const fecthIncomeState = () => {
        fetch((`https://anuariodareciclagem.eco.br/app/yearbook/state/${param}`),
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            })
            .then((response) => response.json())
            .then((json) => setaverageMonthlyState(json))
            .catch(err => { console.log(err) })
    }

    useEffect(() => {
        fecthIncome()
        fecthIncomeState()
    }, [actualYear, param])

    const averageMonthCollectors = averageMonthly
        .filter(item => !newActualYear ? item.year === newActualYear2 : item.year === newActualYear)
        .map((averageMonthly) => averageMonthly.average_monthly_income)
        .filter(item => item > 0 && item !== null)

    const averageMonth = averageMonthly
        .filter(item => !newActualYear ? item.year === newActualYear2 : item.year === newActualYear)
        .map((averageMonthly) => averageMonthly.average_monthly_income)
        .filter(item => item > 0 && item !== null)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const operation = averageMonth / averageMonthCollectors.length

    const averageMonthCollectorsState = averageMonthlyState
    .filter(item => !newActualYear ? item.year === newActualYear2 : item.year === newActualYear)
    .map((averageMonthlyState) => averageMonthlyState.average_monthly_income)
    .filter(item => item > 0 && item !== null)

    const averageMonthState = averageMonthlyState
        .filter(item => !newActualYear ? item.year === newActualYear2 : item.year === newActualYear)
        .map((averageMonthlyState) => averageMonthlyState.average_monthly_income)
        .filter(item => item > 0 && item !== null)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const operationState = averageMonthState / averageMonthCollectorsState.length

    return (
        <>
            <Row className='avaregeIncome'>
                <Col>
                    <p className='textIncome'>Renda média<br />dos catadores<br />mapeados</p>
                    <p className='textIncomeMap'>Selecione no mapa o estado para ver<br />as informações de renda detalhadas</p>
                </Col>
                <div className="mapBox4">
                    <MapBrazil colorLabel='#1a9587' bg='#dddddd' fill='#00594f' colorStroke='#00594f' width={69} height={100} preserveAspectRatio="none" backgroundSize='cover' onChange={setDistrict} PathMap />
                </div>
                <p className='textMap4'>
                    <FontAwesomeIcon icon={faComputerMouse} color='#00594f' /> Selecione no mapa o estado que <br /> deseja para mais informações.
                </p>
                <p className='textIncomeBrasil'>Renda média no Brasil</p>
                <p className='textIncomeBrasil2'>R$ {newActualYear !== 2023 ? Intl.NumberFormat().format(operation.toFixed(2)) : "1.372,52"}</p>
                <p className='textIncomeBrasil3'>R$ {operationState > 0 ? Intl.NumberFormat().format(operationState.toFixed(2)) : 0}</p>
                <img src={image7} alt="Retângulo 12 Anuário da Reciclagem" className='rectangle7Smaller'/>
                <p className="invoiceStateText">Renda média no estado {district}</p>
                {/*<Col>
                    <div className="avaregeIncomeText">
                        Renda média dos
                        catadores <br />mapeados
                        <h6>Amostragem: Referente a {averageMonthCollectors.length} cooperativas analisadas ({percentage.toFixed(2)}%)</h6>
                        <h2 className='totalAvaregeIncome'>R$ {averageMonthCollectors.length !== 0 ? Intl.NumberFormat().format(operation.toFixed(2)) : " "}</h2>
                    </div>
                </Col>*/}
            </Row>
            <ButtonRegion actualYear={actualYear} year={year} />
        </>
    )
}

export default AvaregeIncome