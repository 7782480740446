import './form.css'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const FormToFill = () => {

    var dataAtual = new Date();
    var dia = dataAtual.getDate();
    var mes = (dataAtual.getMonth() + 1);
    var ano = dataAtual.getFullYear();
    var horas = dataAtual.getHours();
    var minutos = dataAtual.getMinutes();
    var seconds = dataAtual.getSeconds();

    const [users, setUsers] = useState([]);
    const [campos, setCampos] = useState({
        nome: '',
        email: '',
        password: '',
        date: `${ano}/${mes}/${dia} ${horas}:${minutos}:${seconds}`
    })

    function clearInputs() {
        setCampos({
            nome: '',
            email: '',
            password: '',
        })
    }

    function handleInputChange(e) {
        campos[e.target.name] = e.target.value;
        setCampos(campos);
    }

    function handleClick() {
        if (campos !== '') {
            fetch(('https://anuariodareciclagem.eco.br/app/user/register'),
                {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(campos),
                })
                .then((response) => response.json())
                .then((json) => setCampos(json))
                .catch(err => console.log(err));
        }
    }

    function verifyEmail() {
        fetch((`https://anuariodareciclagem.eco.br/app/user`))
            .then((response) => response.json())
            .then((json) => setUsers(json))
            .catch(error => {
                throw (error)
            })
    }

    function handleFormSubmit(event) {
        event.preventDefault();
        handleClick()
        verifyEmail()
    }

    return (
        <>
            <Row className='formToFill'>
                {campos.email === users.find(user => campos.email) &&
                    <Alert variant="warning">
                        O email {campos.email} já extiste. {" "}
                        <Link to="/UpdateUser" state={{ from: campos.email }} className='alert-link'>Deseja redefinir e senha</Link> ?
                    </Alert>}
                <Row>
                    <h3 className='formToFillText'>Para informações mais detalhas<br />
                        recomendamos fazer o cadastro abaixo.
                    </h3>
                </Row>
                <Row>
                    <Col>
                        <Form id='form'>
                            <Form.Group className="mb-3">
                                <Form.Control placeholder="Nome" name='nome' onChange={handleInputChange} required />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control type='email' placeholder="Email" name='email' onChange={handleInputChange} required />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control type='password' placeholder="Senha" name='password' onChange={handleInputChange} required />
                            </Form.Group>
                            <Button href="/" className='formButton' variant="primary" type="submit" onClick={handleFormSubmit}>
                                Enviar
                            </Button>
                            <Button href="/" className='formButton' variant="primary" type="submit">Voltar</Button>
                        </Form>
                    </Col>
                </Row>
            </Row>
        </>
    )
}

export default FormToFill