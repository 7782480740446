import { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import './commercialized_material.css'
import { actualYear, countFlag } from "../yearbook/yearbook";
import image6 from '../../assets/images/image6.png';
import image7 from '../../assets/images/image7.png';
import image8 from '../../assets/images/image8.png';
import image9 from '../../assets/images/image9.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MapBrazil } from 'react-brazil-map';
import { faComputerMouse } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom';

const CommercializedMaterial = (props) => {

    let [district, setDistrict] = useState('')
    const [material, setMaterial] = useState([])
    const [materialState, setMaterialState] = useState([])
    let count = countFlag;

    let param = useParams()
    param = district;

    const fecthMaterial = () => {
        fetch((`https://anuariodareciclagem.eco.br/app/commercialized_Material/${actualYear() || props.year}`),
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            })
            .then((response) => response.json())
            .then((json) => setMaterial(json))
            .catch(err => { console.log(err) })
    }

    const fecthMaterialState = () => {
        fetch((`https://anuariodareciclagem.eco.br/app/commercialized_Material/state/${param}/${actualYear()}`),
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            })
            .then((response) => response.json())
            .then((json) => setMaterialState(json))
            .catch(err => { console.log(err) })
        console.log(district)
    }

    useEffect(() => {
        fecthMaterial()
        fecthMaterialState()
    }, [count, props.year, actualYear(), param])

    const totalPaper = material
        .map((material) => material.paper)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const totalPlastic = material
        .map((material) => material.plastic)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const totalAluminum = material
        .map((material) => material.aluminum)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const totalOtherMetals = material
        .map((material) => material.other_metals)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const totalGlass = material
        .map((material) => material.glass)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const totalOtherMaterials = material
        .map((material) => material.other_materials)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const totalMaterial = parseFloat(totalPaper) + parseFloat(totalAluminum) + parseFloat(totalGlass) + parseFloat(totalOtherMetals) + parseFloat(totalPlastic) + parseFloat(totalOtherMaterials)

    const totalPaperState = materialState
        .map((materialState) => materialState.paper)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const totalPlasticState = materialState
        .map((materialState) => materialState.plastic)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const totalAluminumState = materialState
        .map((materialState) => materialState.aluminum)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const totalOtherMetalsState = materialState
        .map((materialState) => materialState.other_metals)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const totalGlassState = materialState
        .map((materialState) => materialState.glass)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const totalOtherMaterialsState = materialState
        .map((materialState) => materialState.other_materials)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const totalMaterialState = parseFloat(totalPaperState) + parseFloat(totalAluminumState) + parseFloat(totalGlassState) + parseFloat(totalOtherMetalsState) + parseFloat(totalPlasticState) + parseFloat(totalOtherMaterialsState)

    function unique(value, index, self) {
        return self.indexOf(value) === index;
    }

    return (
        <>
            <Row className='comercializacao'>
                <img src={image6} alt="Retângulo 6 Anuário da Reciclagem" className='rectangle6'/>
                <img src={image7} alt="Retângulo 7 Anuário da Reciclagem" className='rectangle7'/>
                <img src={image8} alt="Retângulo 8 Anuário da Reciclagem" className='rectangle8'/>
                <img src={image9} alt="Retângulo 9 Anuário da Reciclagem" className='rectangle9'/>
                <Col className="comercializado">
                    de resíduos foram recuperados pelas<br /> organizações mapeadas
                </Col>
                <Col className="totalMaterial">
                    {Intl.NumberFormat().format(totalMaterial.toFixed(2))} <p className="ton"> ton </p>
                </Col>
                <Col className="detalhesTexto">
                    Selecione no mapa o estado que deseja para ver com detalhes<br /> as informações de resíduos recuperados.
                </Col>
                <div className="mapBox2">
                    <MapBrazil colorLabel='#1a9587' bg='#dddddd' fill='#00594f' colorStroke='#00594f' width={69} height={100} preserveAspectRatio="none" backgroundSize='cover' onChange={setDistrict} PathMap />
                </div>
                <p className='textMap2'>
                    <FontAwesomeIcon icon={faComputerMouse} color='#00594f' /> Selecione no mapa o estado que <br /> deseja para mais informações.
                </p>
                <p className="text1">Papéis: <p className="text7">{Intl.NumberFormat().format(totalPaperState.toFixed(2))} <p className="text13">ton</p></p></p>
                <p className="text2">Plásticos: <p className="text8">{Intl.NumberFormat().format(totalPlasticState.toFixed(2))} <p className="text14">ton</p></p></p>
                <p className="text3">Vidros: <p className="text9">{Intl.NumberFormat().format(totalGlassState.toFixed(2))} <p className="text15">ton</p></p></p>
                <p className="text4">Metais: <p className="text10">{Intl.NumberFormat().format((totalAluminumState + totalOtherMetalsState).toFixed(2))} <p className="text16">ton</p></p></p>
                <p className="text5">Outros: <p className="text11">{Intl.NumberFormat().format(totalOtherMaterialsState.toFixed(2))} <p className="text17">ton</p></p></p>
                <p className="text6">Total: <p className="text12">{Intl.NumberFormat().format(totalMaterialState.toFixed(2))} <p className="text18">ton</p></p></p>
            </Row>
        </>
    )

}

export default CommercializedMaterial