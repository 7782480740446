import Row from 'react-bootstrap/esm/Row'
import Card from 'react-bootstrap/esm/Card'
import '../../components/average_monthly_income/avaregeIncome.css'
import { useState } from 'react'
import { useEffect } from 'react'
import { Interaction } from 'chart.js'
import './cardAvarege.css'

const CardAvarege = ({ param, actualYear, year }) => {
    const newActualYear = parseInt(actualYear)
    const newActualYear2 = parseInt(year)

    const [avaregeRegion, setAvaregeRegion] = useState([])

    useEffect(() => {
        fetch(`https://anuariodareciclagem.eco.br/app/yearbook/region/${param}`)
            .then((response) => response.json())
            .then((json) => setAvaregeRegion(json))
            .catch((err) => console.log(err))
    }, [param, actualYear])

    const averageMonthCollectorsRegion = avaregeRegion
        .filter(item => !newActualYear ? item.year === newActualYear2 : item.year === newActualYear)
        .map((averageMonthly) => averageMonthly.average_monthly_income)
        .filter(item => item > 0 && item !== null)

    const averageMonthRegion = avaregeRegion
        .filter(item => !newActualYear ? item.year === newActualYear2 : item.year === newActualYear)
        .map((averageMonthly) => averageMonthly.average_monthly_income)
        .filter(item => item > 0 && item !== null)
        .reduce((previousValue, currValue) => parseFloat(previousValue) + parseFloat(currValue), 0)

    const operation = averageMonthRegion / averageMonthCollectorsRegion.length

    function FiltraNaN(value) {
        return !Number.isNaN(value[4])
    }

    const newArrayAverege = avaregeRegion
        .filter(item => !newActualYear ? item.year === newActualYear2 : item.year === newActualYear)
        .map((avaregeRegion) => [avaregeRegion.cooperative_id, avaregeRegion.state, avaregeRegion.region, avaregeRegion.name, parseFloat(avaregeRegion.average_monthly_income)])
        .filter(FiltraNaN)

    let objArray = []

    newArrayAverege.forEach(element => {
        let obj = {}
        obj.cooperative_id = element[0]
        obj.state = element[1]
        obj.region = element[2]
        obj.name = element[3]
        obj.avarege = element[4]

        objArray.push(obj)
        return element
    })

    const groups = objArray.reduce((acc, curr) => {
        if (!acc[curr.state]) {
            acc[curr.state] = {
                ...curr,
            }
            acc[curr.state].name = 0
            acc[curr.state].avarege = 0;
        }
        acc[curr.state].name = acc[curr.state].name + 1
        acc[curr.state].avarege = parseFloat(acc[curr.state].avarege) + parseFloat(curr.avarege)
        return acc
    }, {})

    const newList = Object.values(groups)

    const groups2 = objArray.reduce((acc, curr) => {
        if (!acc[curr.name]) {
            acc[curr.name] = {
                ...curr,
            }
            acc[curr.name].cooperative_id = 0
            acc[curr.name].avarege = 0;
        }
        acc[curr.name].cooperative_id = acc[curr.name].cooperative_id + 1
        acc[curr.name].avarege = parseFloat(acc[curr.name].avarege) + parseFloat(curr.avarege)
        return acc
    }, {})

    const newList2 = Object.values(groups2)

    return (
        <>
            <Row className='cardGroupeAvarege'>
                <Card className='cardavAregeIndex' style={{ width: '14rem', height: '9rem' }}>
                    <Card.Body className='bodyCard'>
                        <Card.Text className='cardText'>
                            Média Região
                            R$ {averageMonthCollectorsRegion.length !== 0 ? operation.toFixed(2).replace(".", ",") : " "}
                        </Card.Text>
                    </Card.Body>
                </Card>
                {newList.map((newList, index) =>
                    newList.length !== 0 &&
                    <Card className='cardAvarege' style={{ width: '14rem', height: '9rem' }} key={index}>
                        <Card.Title className='avaregeTitle'>{newList.state}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">{`Total do Estado R$ ${Intl.NumberFormat().format(newList.avarege / newList.name)}`}</Card.Subtitle>
                        <Card.Body className='bodyCardAvarege'>
                            {newList2.map((newList2) =>
                            newList2.state === newList.state &&
                                <Card.Text className='cardTextAvarege'>
                                    {`${newList2.name} R$ ${Intl.NumberFormat().format(newList2.avarege / newList2.cooperative_id)}`}
                                </Card.Text>
                            )}
                        </Card.Body>
                    </Card>
                )}
            </Row>
        </>
    )
}

export default CardAvarege