import { useEffect } from "react"
import { useState } from "react"
import Button from "react-bootstrap/esm/Button"
import ButtonGroup from "react-bootstrap/esm/ButtonGroup"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"

const ButtonLetterInvoice = ({ year, param }) => {

    const [cooperative, setCooperative] = useState([])

    useEffect(() => {
        fetch(`https://anuariodareciclagem.eco.br/app/cooperative`)
            .then((response) => response.json())
            .then((json) => setCooperative(json))
            .catch((error) => error)
    }, [])

    function Unique(value, index, self) {
        return self.indexOf(value) === index
    }

    const newArray = []
    const nameCooperative = cooperative.map((cooperative) => cooperative.name)
    nameCooperative.forEach(element => newArray.push(element.substring(0, 1)))
    const newArray2 = newArray.filter(Unique).sort().splice(1).splice(1).splice(0, 23)

    const [result, setResult] = useState([])
    const [itemSearch, setItemSearch] = useState('')

    useEffect(() => {
        fetch(`https://anuariodareciclagem.eco.br/app/yearbook/state/${param}`)
            .then((response) => response.json())
            .then((json) => setResult(json))
            .catch((error) => console.log(error))
    }, [itemSearch, param, year])

    const newArr = result.filter(item => item.total_revenue !== null).map((item) => [item.name, item.total_revenue])
 
    function HandleClick(e) {
        setItemSearch(e.target.value)
    }

    const arrayCity = []
    newArr.forEach(element => {
        let obj = {}
        obj.name = element[0]
        obj.total_revenue = element[1]
        arrayCity.push(obj)
    })

    const filtered = arrayCity.filter(item => item.total_revenue !== null)

    const totalArrayCity = filtered.reduce((acc, curr) => {
        if(!acc[curr.name]){
            acc[curr.name] = {
                ...curr
            }
            acc[curr.name].total_revenue = 0
        }
        acc[curr.name].total_revenue = acc[curr.name].total_revenue + parseFloat(curr.total_revenue)
        return acc
    },{})
    const newList = Object.values(totalArrayCity)

    const arrayCity2 = []
    newList.forEach(element => {
        if (element.name.substring(0,1) === itemSearch) {
            let obj = {}
            obj.state = element
            arrayCity2.push(obj)
        }
    })
    const newList2= arrayCity2.map((arrayCity2) => arrayCity2.state)

    return (
        <>
            <div className="letterButton">
                {newArray2.map((newArray2, index) =>
                    <ButtonGroup aria-label="Basic example" key={index}>
                        <Button className="letterButton" variant="secondary" value={newArray2} onClick={HandleClick}>{newArray2}</Button>
                    </ButtonGroup>
                )}
                <div className="cityQuantity">
                    <Row className=" justify-content-md-center">
                        {newList2.map((newList2, index) =>
                        newList2.total_revenue != 0 &&
                            <>
                                <Col md={4} >
                                    {newList2.name}
                                </Col>
                                <Col >
                                    R${Intl.NumberFormat().format(newList2.total_revenue)}
                                </Col>
                            </>
                        )}
                    </Row>
                </div>
            </div>
        </>
    )
}

export default ButtonLetterInvoice