import { useEffect } from 'react';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ButtonLetterInvoice from '../filterLettersInvoice/buttonLetterInvoice';
// import './searchRegionAgreement.css'
import { faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const SearchInvouce = ({ year, param }) => {
    const newYear = parseInt(year)

    const [itemSearch, setItemSearch] = useState('')

    function HandleChange(e) {
        setItemSearch(e.target.value)
    }

    const [result, setResult] = useState([])

    useEffect(() => {
        fetch(`https://anuariodareciclagem.eco.br/app/yearbook/state/${param}`)
            .then((response) => response.json())
            .then((json) => setResult(json))
            .catch((error) => console.log(error))
    }, [itemSearch, year, newYear])

    const filtered = result
        .filter(item => item.year === newYear && item.total_revenue !== null && item.total_revenue > 0 && item.name === itemSearch)
        .map((result) => [result.name, result.total_revenue])

    let objArray3 = []

    filtered.forEach(element => {
        let obj = {}
        obj.name = element[0]
        obj.total_revenue = element[1]

        objArray3.push(obj)
        return element
    })

    const groups = objArray3.reduce((acc, curr) => {
        if (!acc[curr.name]) {
            acc[curr.name] = {
                ...curr
            }
            acc[curr.name].total_revenue = 0;
        }

        acc[curr.name].total_revenue = acc[curr.name].total_revenue + parseFloat(curr.total_revenue)

        return acc
    }, {})

    const newList = Object.values(groups)

    return (
        <>
            <div className="search">
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                    <Form.Control aria-describedby="basic-addon2" placeholder='Digita a cidade' onChange={HandleChange} />
                </InputGroup>
                {itemSearch && newList.map((newList) =>
                    <h4 text-center>{`A Cidade de ${newList.name} faturou R$${Intl.NumberFormat().format(newList.total_revenue)} em ${newYear}`}
                    </h4>
                )}
            </div>
            {year && <ButtonLetterInvoice year={year} param={param}/>}
        </>
    )
}

export default SearchInvouce