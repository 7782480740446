import { useEffect } from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useParams } from 'react-router-dom';
import CardVolumeMaterials from "../card_volume_material"
import CardMaterial from "../card_meterial";

import './button.css'

const ButtonFilter = (props) => {

    const [region, setRegion] = useState(["Centro-Oeste"])

    useEffect(() => {
        fetch('https://anuariodareciclagem.eco.br/app/city')
            .then((response) => response.json())
            .then((json) => setRegion(json))
    }, [])

    const unicParamRegion = region.map((region) => region.region).sort()

    function unique(value, index, self) {
        return self.indexOf(value) === index;
    }

    const singleparamRegions = unicParamRegion.filter(unique).sort((a, b) => a.unicParamRegion > b.unicParamRegion ? -1 : 1)

    const [paramRegion, setParamparamRegion] = useState('')
    const [showResultsRegion, setShowResultsRegion] = useState(false)

    const HandleClickRegion = (e) => {
        setParamparamRegion(e.target.value)
        setShowResultsRegion(true)
    };

    const [state, setState] = useState(["DF"])

    useEffect(() => {
        fetch('https://anuariodareciclagem.eco.br/app/city')
            .then((response) => response.json())
            .then((json) => setState(json))
    }, [])

    const unicState = state.map((state) => state.state)

    const arrayStates = [
        'DF','GO','MS','MT','AL','BA','CE','MA','PB','PE','PI','RN','SE','AC','AM','AP','PA','RO','RR','TO','ES','MG','RJ','SP','PR','RS','SC'
    ]

    const [paramState, setParamState] = useState('')
    const [showResultsState, setShowResultsState] = useState(false)

    const HandleClickState = (e) => {
        setParamState(e.target.value)
        setShowResultsState(true)
    }

    return (
        <>
            <div className="buttonGroup">
                {singleparamRegions.map((singleparamRegion, index) =>
                    <ButtonGroup aria-label="Basic example" key={index}>
                        <Button className={`button-${singleparamRegion}`} variant="secondary"
                            value={singleparamRegion}
                            onClick={HandleClickRegion}>{singleparamRegion}</Button>
                    </ButtonGroup>
                )}
            </div>
            <div className="buttonGroup">
                {arrayStates.map((arrayStates, index) =>
                    <ButtonGroup aria-label="Basic example" key={index}>
                        <Button className={`button-${arrayStates}`} variant="secondary"
                            value={arrayStates}
                            onClick={HandleClickState}>{arrayStates}</Button>
                    </ButtonGroup>
                )}
                {showResultsRegion && <CardVolumeMaterials paramRegion={paramRegion} actualYear3={props.actualYear2} year={props.year} />}
                {showResultsState && <CardMaterial paramState={paramState} actualYear4={props.actualYear2} year={props.year} />}
            </div>
        </>
    )
}

export default ButtonFilter