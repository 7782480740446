import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './cooperative.css'
import { actualYear } from '../yearbook/yearbook';
import image5 from '../../assets/images/image5.png'
import { faComputerMouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collerctors from "../active_collectors/active_collectors";
import { MapBrazil } from 'react-brazil-map';

let stateFlag = '';
let conditionalFlag = 0;

const Cooperative2 = (props) => {

    let [district, setDistrict] = useState('')
    const [cooperativeState, setCooperativeState] = useState([]);

    let param = useParams()
    param = district

    const fetchUrl = async () => {
        try {
            const response = await fetch((`https://anuariodareciclagem.eco.br/app/cooperative/state/${param}/${actualYear() || props.yearLogged}`),
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                })  ;
            setCooperativeState(await response.json());
            stateFlag = param
            conditionalFlag = 2
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchUrl()
    }, [param, actualYear(), props.yearLogged])

    function stateNumber() {
        if (conditionalFlag === 1) {
            return props.year.length || cooperativeState.length
        } else {
            return cooperativeState.length
        }
    }

    return (
        <>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
            <div className="divisor">
                {/*<div className="image">
                    <img src={imgSection2} alt="bla" className='imgSection2' />
                </div>*/}
                <Row className="divisor">
                    {/*<Col >
                        <Card className='cardCooperative' style={{ width: '12rem', height: '7rem' }}>
                            <Card.Title className='cardTitle'>{district}</Card.Title>
                            <Card.Body className='quantityCard'>{stateNumber()}</Card.Body>
                        </Card>
                    </Col>*/}
                    <Col>
                        <img src={image5} alt="Retângulo 5 Anuário da Reciclagem" className='rectangle5' />
                        <div className='divisor2'>
                            <MapBrazil colorLabel='#1a9587' bg='#FFFFFF' fill='#00594f' colorStroke='#00594f' width={69} height={100} preserveAspectRatio="none" backgroundSize='cover' onChange={setDistrict} PathMap />
                            <p className="p">
                                Em {actualYear()}, foram mapeadas
                            </p>
                            <p className="p3">
                                organizações de catadores em<br />território nacional.
                            </p>
                            <p className="p4">
                                No mesmo período, foram contabilizados
                            </p>
                            <p className="p6">
                                catadores e catadoras atuantes nas<br />organizações mapeadas.
                            </p>
                            <p className="p7">
                                {Intl.NumberFormat().format(stateNumber())}
                            </p>
                            <p className="p8">
                                organizações de catadores no<br />estado {district}.
                            </p>
                            <p className="p10">
                                catadores e catadoras atuantes nas<br />organizações mapeadas.
                            </p>
                        </div>
                        <p className='textMap'>
                            <FontAwesomeIcon icon={faComputerMouse} color='#00594f' /> Selecione no mapa o estado que <br /> deseja para mais informações.
                        </p>
                    </Col>
                </Row>
            </div>
            <Collerctors state={param}/>
        </>
    )
}

export function getFlag() {
    conditionalFlag = 1;
    return stateFlag
}

export default Cooperative2