import { useEffect } from 'react'
import { useState } from 'react'
import Col from 'react-bootstrap/esm/Col'
import './agreement.css'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import SearchAgremeent from '../../componentsLogged/agreement/searchRegionAgreement/searchRegionAgreement';


const AgreementDonut = ({actualYear, year, region}) => {
    const newActualYear = parseInt(actualYear)
    const newActualYear2 = parseInt(year)

    const [agreement, setAgreement] = useState([])

    useEffect(() => {
        fetch((`https://anuariodareciclagem.eco.br/app/SelectiveCollect/year/${newActualYear || newActualYear2}`),
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            })
            .then((response) => response.json())
            .then((json) => setAgreement(json))
            .catch((err) => console.log(err))
    }, [actualYear, year])

    const allRegion = agreement.map((agreement) => agreement.region)
    function unique(value, index, self) {
        return self.indexOf(value) === index;
    }
    
    const uniqueRegion = allRegion.filter(unique).sort()

    function CentroOeste(value) {
        return value.region === 'Centro-Oeste' && value.agreement === 1 & value.agreement !== null;
    }
    function Sudeste(value) {
        return value.region === 'Sudeste' && value.agreement === 1 & value.agreement !== null;
    }
    function Sul(value) {
        return value.region === 'Sul' && value.agreement === 1 & value.agreement !== null;
    }
    function Norte(value) {
        return value.region === 'Norte' && value.agreement === 1 & value.agreement !== null;
    }
    function Nordeste(value) {
        return value.region === 'Nordeste' && value.agreement === 1 & value.agreement !== null;
    }

    let centroOeste = agreement.filter(CentroOeste).length
    let sudeste = agreement.filter(Sudeste).length
    let sul = agreement.filter(Sul).length
    let norte = agreement.filter(Norte).length
    let nordeste = agreement.filter(Nordeste).length

    let newArray = Object.values([ centroOeste ,  nordeste , norte , sudeste ,  sul ])

    ChartJS.register(ArcElement, Tooltip, Legend);
    const data = {
        labels: uniqueRegion,
        datasets: [
            {
                label: '',
                data: newArray,
                backgroundColor: [
                    'rgba(213, 130, 74, 0.5)',
                    'rgba(83, 132, 128, 0.5)',
                    'rgba(36, 106, 133, 0.5)',
                    'rgba(53, 70, 138, 0.5)',
                    'rgba(184, 100, 95, 0.5)',
                ],
                borderColor: [
                    'rgba(213, 130, 74, 1)',
                    'rgba(83, 132, 128, 1)',
                    'rgba(36, 106, 133, 1)',
                    'rgba(53, 70, 138, 1)',
                    'rgba(184, 100, 95, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <>
            <Col>
                <div className="donut">
                    <Doughnut data={data}/>
                </div>
            </Col>
            {year && <SearchAgremeent year={year} region={region}/>}
        </>
    )
}

export default AgreementDonut