import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import Card from 'react-bootstrap/esm/Card'
import './agreement.css'
import AgreementDonut from './agreementDonut'

const AgreementCard = ({ actualYear, year }) => {
    const newActualYear = parseInt(actualYear)
    const newActualYear2 = parseInt(year)

    const [agreement, setAgreement] = useState([])

    useEffect(() => {
        fetch((`https://anuariodareciclagem.eco.br/app/SelectiveCollect/year/${newActualYear || newActualYear2}`),
            {

            })
            .then((response) => response.json())
            .then((json) => setAgreement(json))
            .catch((err) => console.log(err))
    }, [actualYear, newActualYear2])

    const unicRegion = agreement.map((agreement) => agreement.region)

    function unique(value, index, self) {
        return self.indexOf(value) === index;
    }

    const singleRegions = unicRegion.filter(unique).sort()

    const [param, setParam] = useState('Norte')

    const HandleClick = (e) => {
        setParam(e.target.value)
    };

    const [byRegion, setByRegion] = useState([])

    useEffect(() => {
        fetch(`https://anuariodareciclagem.eco.br/app/SelectiveCollect/region/${param}`)
            .then((response) => response.json())
            .then((json) => setByRegion(json))
    }, [param])

    const haveAgreement = byRegion.filter(item => {
        if (!newActualYear) {
            return item.year === newActualYear2 && item.agreement === 1
        } else {
            return item.year === newActualYear && item.agreement === 1
        }
    })

    let haveAgreementState2 = [];
    function findOcc(haveAgreement, key) {

        haveAgreement.forEach((x) => {

            if (haveAgreementState2.some((val) => { return val[key] == x[key] })) {
                haveAgreementState2.forEach((k) => {
                    if (k[key] === x[key]) {
                        k["agreement"]++
                    }
                })
            } else {
                let a = {}
                a[key] = x[key]
                a["agreement"] = 1
                haveAgreementState2.push(a);
            }
        })
        return haveAgreementState2
    }

    let key = "state"
    const newArray = findOcc(haveAgreement, key).sort((a, b) => a.state > b.state ? 1 : -1)

    return (
        <>
            <div className="AgreementCard">
                <Row>
                    <Col>
                        <div className="buttonGroupAgreement">
                            {singleRegions.map((singleRegion, index) =>
                                <ButtonGroup aria-label="Basic example" >
                                    <Button className="button" variant="secondary" key={index}
                                        value={singleRegion}
                                        onClick={HandleClick}>{singleRegion}</Button>
                                </ButtonGroup>
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <h5 className='text-center'>
                        {haveAgreement.length > 0 ? haveAgreement.length : 0}<br />
                        Cooperativas com <br />
                        contratos com prefeituras
                    </h5>
                    {newArray.map((newArray, index) =>
                        <>
                            <Card className='cardAgreement' style={{ width: '7rem', height: '7rem' }} key={index}>
                                <Card.Body className='bodyCardAgreement'>
                                    <Card.Text className='cardTextAgreement'>
                                        {newArray.state}<br />
                                        {newArray.agreement}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </>
                    )}
                </Row>
            </div>
            <AgreementDonut actualYear={actualYear} year={year} region={param} />
        </>
    )
}

export default AgreementCard