import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Form from "react-bootstrap/esm/Form";
import { useEffect, useState } from "react";
import "../../components/yearbook/yearBook.css"
import Cooperative2, { getFlag } from "../../components/cooperative/cooperative2";
import logo from '../../assets/images/Logo.png'
import Cooperative, { getConditionalFlag } from "../../components/cooperative/cooperative";
import Search from "../searchStateCooperative/seacrhStateCooperative";
import ButtonLetter from '../filterLettersCooperative/buttonLetter'
import Collerctors from "../../components/active_collectors/active_collectors";
import CommercializedMaterial from "../../components/commercialized_material/commercialized_material";
import VolumeMaterials from "../../components/volume_materials/volume_material";
import LinkDownload from "../../components/linkDownload/linkDownload";
import Agreement from "../../components/agreement/agreement";
import { actualYear } from "../../components/yearbook/yearbook";
import InvoiceMap from "../../components/invoice/invoiceMap";
import AvaregeIncome from "../../components/average_monthly_income/avaregeIncome";
import DownloadYearbook from "../download/downloadYearbook";

export let countFlag = 0;


const YearBook = () => {

    const [yearbook, setYearbook] = useState([]);

    useEffect(() => {
        fetch('https://anuariodareciclagem.eco.br/app/yearbook',
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }
        )
            .then((response) => response.json())
            .then((json) => setYearbook(json))
    }, []);

    var years = yearbook.map(yearbook => yearbook.year)
    
    function unique(value, index, self) {
        return self.indexOf(value) === index;
    }
    
    var uniqueyears = years.filter(unique).sort(function(a, b){return b-a});

    const [year, setYear] = useState(2022)

    const [cooperativeState, setCooperativeState] = useState([]);
    const fetchState = async () => {
        try {
            const response = await fetch(`https://anuariodareciclagem.eco.br/app/cooperative/state/${getFlag()}/${actualYear()}`);
            setCooperativeState(await response.json());
            countFlag = countFlag + 1;
        } catch (error) {
            console.log(error);
        }
    };
    
    function HandleChange(e) {
        setYear(e.target.value)
        fetchState()
    }

    
    return(
        <>
            <Row className="yearbook" xs={12}>
                <Col>
                    <img src={logo} alt="" width="360" height="185" className="logo"/>
                </Col>
                <Col>
                    <div className="introduction">
                        <p>
                            O Anuário da Reciclagem é o maior repositório digital de informações sobre catadores e catadoras e seu papel na cadeia da reciclagem no Brasil.
                            Entenda a importância e o papel desses atores nesse seguimento econômico estratégico para a sustentabilidade do planeta,
                            navegando em uma importante ferramenta de análise da gestão de resíduos sólidos no país.
                        </p>
                        <Form.Group className='select' controlId="formPlaintextYear">
                            <Form.Label>
                                Selecione o ano
                            </Form.Label>
                            <Col>
                                <Form.Control type="submit" as="select" className="selectYear" aria-label="Default select" size='sm' onChange={HandleChange}>
                                    {uniqueyears.map((uniqueyear, index) => (
                                        <option key={index}>{uniqueyear}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </div>
                </Col>
            </Row>  
            <Cooperative yearLogged={year}/>
            <Cooperative2 yearLogged={year} year={cooperativeState}/>
            <Search year={year}/>
            <ButtonLetter year={year}/>
            <Collerctors year={year}/>
            <CommercializedMaterial year={year}/>
            <VolumeMaterials year={year}/>
            <LinkDownload/> 
            <Agreement year={year}/> 
            <InvoiceMap year={year}/>
            <AvaregeIncome year={year}/>
            <DownloadYearbook/>
        </> 
    )
}


export default YearBook