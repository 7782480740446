import React, { useEffect } from 'react'
import { useState } from 'react'
import Row from 'react-bootstrap/esm/Row'
import Card from 'react-bootstrap/esm/Card'
import { actualYear } from '../../components/yearbook/yearbook';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/Modal';
import { faCoffee, faPersonDress, faPerson } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './activeCollectors.css'
import Table from 'react-bootstrap/Table';

let region = '';
let conditionalFlag = 0;


const CardActiveCollectorsLogged = (props) => {

    const [collectorsRegion, setCollectorRegion] = useState([])

    const fecthUrl = async () => {
        try {
            region = props.param;
            conditionalFlag = 2;
            fetch((`https://anuariodareciclagem.eco.br/app/activecollectors/region/${props.param}/${actualYear() || props.year}`),
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                })
                .then((response) => response.json())
                .then((json) => setCollectorRegion(json))
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fecthUrl();
    }, [props.param, actualYear(), props.year])

    var entire = 0

    const groups = collectorsRegion.reduce((collectos, current) => {
        if (!collectos[current.state]) {
            collectos[current.state] = {
                ...current
            }
            collectos[current.state].man = 0;
            collectos[current.state].woman = 0;
            collectos[current.state].city_id = 0;
        }
        collectos[current.state].man = collectos[current.state].man + current.man
        collectos[current.state].woman = collectos[current.state].woman + current.woman
        collectos[current.state].city_id = collectos[current.state].man + collectos[current.state].woman

        return collectos
    }, {})

    const groups2 = collectorsRegion.reduce((collectos, current) => {
        if (!collectos[current.name]) {
            collectos[current.name] = {
                ...current
            }
            collectos[current.name].man = 0;
        }
        collectos[current.name].man = collectos[current.name].man + current.man + current.woman

        return collectos
    }, {})

    const newList = Object.values(groups)
    const newList2 = Object.values(groups2)
    const newList2Filtered = newList2.filter(item => item.man !== 0)

    const sumCollectors = newList
        .map((newList) => newList.city_id)
        .filter(item => item !== 0)
        .reduce((previousValue, CurrentValue) => previousValue + CurrentValue, 0)

    function regionNumber() {
        if (conditionalFlag === 1) {
            return Intl.NumberFormat().format(props.regionTotal2);
        } else {
            return Intl.NumberFormat().format(sumCollectors);
        }
    }

    const [show, setShow] = useState(false);
    const [stateParam, setStateParam] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
        setStateParam(e.target.value)
    };

    let statesCollectors = newList.length > 0 && newList.map((newList, index) => (
        newList.man > 0 &&
        <Card className='cardCollectos' style={{ width: '12rem', height: '9rem' }} >
            <Card.Title className='titleCollectors' key={index}>{newList.state}</Card.Title>
            <Card.Body className='bodyCardCollectors'>
                <Card.Text className='cardTextCollectors'>
                    {Intl.NumberFormat().format(newList.man)}<br />
                    Catadores
                </Card.Text>
            </Card.Body>
        </Card>
    ));

    if (conditionalFlag === 1) {
        statesCollectors = newList.length > 0 && props.statesCollectors2?.map((newList, index) => (
            newList.man > 0 &&
            <Card className='cardCollectos' style={{ width: '11rem', height: '12rem' }} >
                <Card.Title className='titleCollectors' key={index}>{newList.state}</Card.Title>
                <Card.Body className='bodyCardCollectors'>
                    <Card.Text className='cardTextCollectors'>
                        {Intl.NumberFormat().format(newList.city_id)} Catadores <br />
                        {Intl.NumberFormat().format(newList.man)} H <FontAwesomeIcon icon={faCoffee} size="xs" />
                        {Intl.NumberFormat().format(newList.woman)} M <FontAwesomeIcon icon={faCoffee} size="xs" />
                    </Card.Text>
                    <Button variant="primary" onClick={handleShow} >Ver mais</Button>
                </Card.Body>
            </Card>
        ))
    } else {
        statesCollectors = newList.length > 0 && newList.map((newList, index) => (
            newList.man > 0 &&
            <>
                <Card className='cardCollectos' style={{ width: '11rem', height: '12rem' }} >
                    <Card.Title className='title' key={index}>{newList.state}</Card.Title>
                    <Card.Body className='bodyCardCollectors'>
                        <Card.Text className='cardTextCollectors'>
                            {Intl.NumberFormat().format(newList.city_id)} Catadores <br />
                            {Intl.NumberFormat().format(newList.man)} <FontAwesomeIcon icon={faPerson} /> <br />
                            {Intl.NumberFormat().format(newList.woman)} <FontAwesomeIcon icon={faPersonDress} />
                        </Card.Text>
                        <Button size="sm" className='buttonCard' variant="primary" value={newList.state} onClick={handleShow}>Ver mais</Button>
                    </Card.Body>
                </Card>
                <Modal className='modalCollectors' show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{stateParam}<br /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Cidades</th>
                                    <th>Homens</th>
                                    <th>Mulheres</th>
                                    <th>Qtd de Catadores</th>
                                </tr>
                            </thead>
                            <tbody>
                                {newList2Filtered.map((newList2) => (
                                    newList2.state === stateParam &&
                                    <>
                                        <tr>
                                            <td>{newList2.name}</td>
                                            <td>{newList2.man}</td>
                                            <td>{newList2.woman}</td>
                                            <td>{newList2.woman + newList2.man}</td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
            </>
        ))
    }

    return (
        <>
            <Row className='cardGroup'>
                <Card className='cardIndex' style={{ width: '11rem', height: '12rem' }}>
                    <Card.Body className='bodyCard'>
                        <Card.Text className='cardText'>
                            Total na região <br />
                            {regionNumber()}
                        </Card.Text>
                    </Card.Body>
                </Card>
                {statesCollectors}
            </Row>
        </>
    )
}

export function getRegion() {
    conditionalFlag = 1;
    return region;
}

export default CardActiveCollectorsLogged