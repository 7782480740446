import { useEffect, useState } from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import './agreement.css'
import AgreementCard from './agreementCard'
import AgreementDonut from './agreementDonut'

const Agreement = ({actualYear, year}) => {
    const newActualYear = parseInt(actualYear)
    const newActualYear2 = parseInt(year)
    
    const [agreement, setAgreement] = useState([])

    useEffect(() => {
        fetch((`https://anuariodareciclagem.eco.br/app/SelectiveCollect/year/${newActualYear || newActualYear2}`),
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            })
            .then((response) => response.json())
            .then((json) => setAgreement(json))
            .catch((err) => console.log(err))
    }, [actualYear, year])

    const newArray = agreement.filter(item => item.agreement !== 0 && item.agreement !== null && item.agreement === 1).map((agreement) => agreement.agreement)
    const newArray2 = agreement.filter(item => item.agreement !== 0 && item.agreement !== null )
    const percentage = newArray.length / agreement.length * 100

    return (
        <>
            <Row className='agreement'>
                <Col>
                    <div className="agreementCollect">
                        Organizações de catadores mapeadas que<br/> possuem acordos de 
                        coleta seletiva com prefeituras
                        <h6>Amostragem: Referente a {newArray2.length} cooperativas analisadas ({percentage.toFixed(2)}%)</h6>
                    </div>
                </Col>
            </Row>
            <Row>
                {<AgreementCard actualYear={actualYear} year={year}/>}
            </Row>
        </>
    )
}

export default Agreement