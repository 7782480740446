import './Home.css'
import YearBook from '../components/yearbook/yearbook';
import Login from '../components/login/login';

const Signin = () => {
    return (
        <>
            <Login/>
        </>
    )
}

export default Signin