import React, { useEffect } from 'react'
import { useState } from 'react'
//import Row from 'react-bootstrap/esm/Row'
import Card from 'react-bootstrap/esm/Card'
import { actualYear } from '../yearbook/yearbook';
import './active_collectors.css'

let region = '';
let state = '';
let conditionalFlag = 0;

const CardActiveCollectors = (props) => {

    const [collectorsRegion, setCollectorRegion] = useState([]);
    const [collectorsState, setCollectorState] = useState([]);

    const fecthUrl = async () => {
        try{
            region = props.param;
            conditionalFlag = 2;
            fetch((`https://anuariodareciclagem.eco.br/app/activecollectors/region/${props.param}/${actualYear() || props.year}`),
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                })
                .then((response) => response.json())
                .then((json) => setCollectorRegion(json))
        } catch (error) {
            console.log(error);
        }
    };

    const fecthUrlState = async () => {
        try{
            state = props.state3;
            conditionalFlag = 2;
            fetch((`https://anuariodareciclagem.eco.br/app/activecollectors/state/${props.state3}/${actualYear() || props.year}`),
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                })
                .then((response) => response.json())
                .then((json) => setCollectorState(json))
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fecthUrl();
        fecthUrlState();
    }, [props.param, actualYear(), props.year, props.state3]);

    var entire = 0;

    const groups = collectorsRegion.reduce((collectos, current, entire) => {
        if (!collectos[current.state]) {
            collectos[current.state] = {
                ...current
            }   
            collectos[current.state].man = 0;
        }
        collectos[current.state].man = collectos[current.state].man + current.man + current.woman

        return collectos
    }, {});

    const groupsState = collectorsState.reduce((collectos, current, entire) => {
        if (!collectos[current.state]) {
            collectos[current.state] = {
                ...current
            }   
            collectos[current.state].man = 0;
        }
        collectos[current.state].man = collectos[current.state].man + current.man + current.woman

        return collectos
    }, {});

    const newList = Object.values(groups).sort((a, b) => a.state > b.state ? 1 : -1);

    const newListState = Object.values(groupsState).sort((a, b) => a.state > b.state ? 1 : -1);

    const sumCollectors = newList
        .map((newList) => [newList.man, newList.woman])
        .reduce((man, woman) => parseFloat(man) + parseFloat(woman), entire);

    const sumCollectorsState = newListState
        .map((newListState) => [newListState.man, newListState.woman])
        .reduce((man, woman) => parseFloat(man) + parseFloat(woman), entire);

    /*function regionNumber(){
        if (conditionalFlag === 1){
            return Intl.NumberFormat().format(props.regionTotal2);
        } else {
            return Intl.NumberFormat().format(sumCollectors);
        }
    };*/

    let statesCollectors = newList.length > 0 && newList.map((newList, index) => (
        newList.man > 0 &&
        <Card className='cardCollectos' style={{ width: '12rem', height: '9rem' }} key={index}>
            <Card.Title className='title'>{newList.state}</Card.Title>
            <Card.Body className='bodyCard'>
                <Card.Text className='cardText'>
                    {Intl.NumberFormat().format(newList.man)}<br/>
                     Catadores
                </Card.Text>
            </Card.Body>
        </Card>
    ));

    if (conditionalFlag === 1){
        statesCollectors = newList.length > 0 && props.statesCollectors2?.map((newList, index) => (
            newList.man > 0 &&
            <Card className='cardCollectos' style={{ width: '12rem', height: '9rem' }} key={index}>
                <Card.Title className='title'>{newList.state}</Card.Title>
                <Card.Body className='bodyCard'>
                    <Card.Text className='cardText'>
                        {Intl.NumberFormat().format(newList.man)}<br/>
                         Catadores
                    </Card.Text>
                </Card.Body>
            </Card>
        ))
    } else {
        statesCollectors = newList.length > 0 && newList.map((newList, index) => (
            newList.man > 0 &&
            <Card className='cardCollectos' style={{ width: '12rem', height: '9rem' }} key={index}>
                <Card.Title className='title'>{newList.state}</Card.Title>
                <Card.Body className='bodyCard'>
                    <Card.Text className='cardText'>
                        {Intl.NumberFormat().format(newList.man)}<br/>
                         Catadores
                    </Card.Text>
                </Card.Body>
            </Card>
        ))
    };

    return (
        <>
            <p className='p9'>
                {Intl.NumberFormat().format(sumCollectorsState)}
            </p>
            {/*<Row className='cardGroup'>
                <Card className='cardIndex' style={{ width: '11rem', height: '9rem' }}>
                    <Card.Body className='bodyCard'>
                        <Card.Text className='cardText'>
                            Total na região <br/>
                            {regionNumber()}
                        </Card.Text>
                    </Card.Body>
                </Card>
                {statesCollectors}
            </Row>*/}
        </>
    )
}

export function getRegion() {
    conditionalFlag = 1;
    return region;
}

export default CardActiveCollectors