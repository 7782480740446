import Container from 'react-bootstrap/esm/Container.js';
import Signin from './pages/signin.jsx';
import Rotas from './routes/Route.jsx';
import { BrowserRouter } from 'react-router-dom';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
      <Signin/>
        <Container fluid>
          <Rotas/>
        </Container>
      </BrowserRouter>
    </div>
  );
}

export default App;