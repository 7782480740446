import React, { useEffect } from 'react'
import { useState } from 'react'
import Row from 'react-bootstrap/esm/Row'
import Card from 'react-bootstrap/esm/Card'
import Col from 'react-bootstrap/esm/Col'
import './volume_material.css'
import { actualYear } from '../yearbook/yearbook'

let state = ''

const CardMaterial = (props) => {

    const [material, setMaterial] = useState([])

    const fecthUrl = () => {
        fetch((`https://anuariodareciclagem.eco.br/app/commercialized_Material/state/${props.paramState}/${actualYear() || props.year}`),
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            })
            .then((response) => response.json())
            .then((json) => setMaterial(json))
            .catch(err => { console.log(err) })
    }

    useEffect(() => {
        fecthUrl()
    }, [props.paramState, actualYear(), props.year])

    const paper = material
        .map((material) => material.paper)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

        const plastic = material
        .map((material) => material.plastic)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const aluminum = material
        .map((material) => material.aluminum)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const glass = material
        .map((material) => material.glass)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const otherMetals = material
        .map((material) => material.other_metals)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const otherMaterial = material
        .map((material) => material.other_materials)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    function unique(value, index, self) {
        return self.indexOf(value) === index;
    }

    // const newArray = material.map((material) => material.paper, material.plastic, material.aluminum, material.other_materials, material.glass, material.other_metals).filter(item => item !== 0 && item !== null)
    // const newArray2 = material.filter(item => item.paper !== 0 && item.paper !== null).map((material) => material.yearbook_id)
    // const percentage = newArray.length / material.length * 100
    // const countCooperative = newArray2.filter(unique)

    const newArray = material.map((material) => [material.yearbook_id, material.paper, material.plastic, material.aluminum, material.other_materials, material.glass, material.other_metals])
    const count = newArray.filter(item => item[1] !== "0.000")
    const newArray2 = material.filter(item => item.paper !== "0.000").map((material) => material.yearbook_id)
    const percentage = material.length / material.length * 100
    const countCooperative = newArray2.filter(unique)

    state = props.paramState;

    return (
        <>
            <Row className='textCard'>
                <Col>
                    <div className='textCardMaterial'>
                        Tipos de materiais recuperados <br />
                        pelas organizações mapeadas
                        {/* <h6>Amostragem: Referente a {countCooperative.length} cooperativas analisadas ({percentage.toFixed(2)}%)</h6> */}
                    </div>
                </Col>
            </Row>
            <Row className='cardGroupMaterial'>
                {paper !== 0 &&
                    <Card className='cardMaterialsType' style={{ width: '11rem', height: '9rem' }} >
                        <Card.Title className='title'>Papel</Card.Title>
                        <Card.Body className='bodyCard'>
                            <Card.Text className='cardText'>
                                {Intl.NumberFormat().format(paper.toFixed(2))}<p className='ton'>Ton</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                }
                {plastic !== 0 &&
                    <Card className='cardMaterialsType' style={{ width: '11rem', height: '9rem' }} >
                        <Card.Title className='title'>Plástico</Card.Title>
                        <Card.Body className='bodyCard'>
                            <Card.Text className='cardText'>
                                {Intl.NumberFormat().format(plastic.toFixed(2))}<p className='ton'>Ton</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                }
                {aluminum !== 0 &&
                    <Card className='cardMaterialsType' style={{ width: '11rem', height: '9rem' }} >
                        <Card.Title className='title'>Alumínio</Card.Title>
                        <Card.Body className='bodyCard'>
                            <Card.Text className='cardText'>
                                {Intl.NumberFormat().format(aluminum.toFixed(2))}<p className='ton'>Ton</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                }
                {glass !== 0 &&
                    <Card className='cardMaterialsType' style={{ width: '11rem', height: '9rem' }} >
                        <Card.Title className='title'>Vidro</Card.Title>
                        <Card.Body className='bodyCard'>
                            <Card.Text className='cardText'>
                                {Intl.NumberFormat().format(glass.toFixed(2))}<p className='ton'>Ton</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                }
                {otherMetals !== 0 &&
                    <Card className='cardMaterialsType' style={{ width: '11rem', height: '9rem' }} >
                        <Card.Title className='title'>Outros Metais</Card.Title>
                        <Card.Body className='bodyCard'>
                            <Card.Text className='cardText'>
                                {Intl.NumberFormat().format(otherMetals.toFixed(2))}<p className='ton'>Ton</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                }
                {otherMaterial !== 0 &&
                    <Card className='cardMaterialsType' style={{ width: '11rem', height: '9rem' }} >
                        <Card.Title className='title'>Outros Materiais</Card.Title>
                        <Card.Body className='bodyCard'>
                            <Card.Text className='cardText'>
                                {Intl.NumberFormat().format(otherMaterial.toFixed(2))}<p className='ton'>Ton</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                }
            </Row>
        </>
    )
}

export function getState() {
    return state;
}

export default CardMaterial