import YearBookLogged from "../componentsLogged/yearbook/yearbookLogged"

const PageLogged = () => {
    return(
        <>
        <YearBookLogged/>
        </>
    )
}


export default PageLogged