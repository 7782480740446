import Row from "react-bootstrap/esm/Row"
import ButtonFilter from "./buttons/button"
import './volume_material.css'

const VolumeMaterials = (props) => {
    return (
        <>
            <Row className="buttongroup">
                <ButtonFilter actualYear2={props.actualYear} year={props.year}/>
            </Row>
        </>
    )
}

export default VolumeMaterials