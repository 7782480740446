import { Component, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import './login.css';
import { Link, useNavigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';


const Login = () => {

    const [showAlertPassword, setShowAlertPassword] = useState(false);
    const [showAlertEmail, setShowAlertEmail] = useState(false);
    const [show, setShow] = useState(false);
    const [campos, setCampos] = useState({
        email: '',
        password: '',
    })

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShowAlertEmail = () => setShowAlertEmail(false);
    const handleShowAlertPassword = () => setShowAlertPassword(false);
    const navigate = useNavigate();

    function handleInputChange(e) {
        campos[e.target.name] = e.target.value;
        setCampos(campos);
    }

    function handleClick() {
        fetch(('https://anuariodareciclagem.eco.br/app/user/login'),
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(campos),
            })
            .then((response) => response.json())
            .then((json) => {
                if (Object.values(json).length > 0) {
                    navigate("/Home")
                    handleClose()
                    if (Object.values(json)[0] === `Passwords did not match`) {
                        setShowAlertPassword(true)
                        setTimeout(() => {
                            setShowAlertPassword(true) 
                            (false);
                          }, 5000);
                    }
                    if (Object.values(json)[0] === `No account with this email found`) {
                        setShowAlertEmail(true)
                        setTimeout(() => {
                            setShowAlertEmail(true)
                            (false);
                          }, 5000);
                    }
                }
            })
            .catch(err => alert(err));
            console.log('erro');
    }

    function handleFormSubmit(event) {
        event.preventDefault();
        handleClick()
    }

    return (
        <>
            <div className="login">
                {/*<a href="#" onClick={handleShow}><p>Faça o login </p></a>*/}
                <p >|</p>
            </div>
            {/*<Alert variant="warning" showalertpassword={showAlertPassword} onClick={handleShowAlertPassword} dismissible>Senha incorreta</Alert>
            <Alert variant="warning" showalertemail={showAlertEmail} onClick={handleShowAlertEmail} dismissible>Não existe conta com este e-mail</Alert>*/}
            <Modal className="loginModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Faça seu Login!</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bodyModal">
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control type="email" placeholder="E-mail" name="email" onChange={handleInputChange} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword" id="formBasicPassword">
                            <Form.Control type="password" placeholder="Senha" name="password" onChange={handleInputChange} />
                        </Form.Group>
                        <Link to="/UpdateUser" state={{ from: campos.email }} className="forgotPassword" onClick={handleClose}>Esqueci a senha</Link> <br />
                        Não tem conta? <Link className="register" to="/Registration" onClick={handleClose}>Cadastre-se</Link>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button className='enterButton' variant="primary" type="submit" onClick={handleFormSubmit} >
                        Entrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

}

export default Login