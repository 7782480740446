import { useEffect, useState } from "react"
import Card from "react-bootstrap/esm/Card"
import Col from "react-bootstrap/esm/Col"
import Row from "react-bootstrap/esm/Row"
import './volume_material.css'
import { actualYear } from "../yearbook/yearbook" 
import buss from '../../assets/images/onibus.png'

let region = ''

const CardVolumevolumeMaterials = (props) => {

    const [volumeMaterial, setVolumevolumeMaterial] = useState([])

    const fecthUrl = () => {
        fetch((`https://anuariodareciclagem.eco.br/app/commercialized_Material/region/${props.paramRegion}/${actualYear() || props.year}`),
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            })
            .then((response) => response.json())
            .then((json) => setVolumevolumeMaterial(json))
            .catch(err => { console.log(err) })
    }

    useEffect(() => {
        fecthUrl()
    }, [props.paramRegion, props.year, actualYear()])

    useEffect(() => {
        fecthUrl();
    }, [props.actualYear3])

    const totalPaper = volumeMaterial
        .map((volumeMaterial) => volumeMaterial.paper)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const totalPlastic = volumeMaterial
        .map((volumeMaterial) => volumeMaterial.plastic)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const totalAluminum = volumeMaterial
        .map((volumeMaterial) => volumeMaterial.aluminum)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const totalOtherMetals = volumeMaterial
        .map((volumeMaterial) => volumeMaterial.other_metals)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const totalGlass = volumeMaterial
        .map((volumeMaterial) => volumeMaterial.glass)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const totalOtherMaterials = volumeMaterial
        .map((volumeMaterial) => volumeMaterial.other_materials)
        .filter(elem => elem > 0)
        .reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue), 0)

    const totalvolumeMaterial = parseFloat(totalPaper) + parseFloat(totalAluminum) + parseFloat(totalGlass) + parseFloat(totalOtherMetals) + parseFloat(totalPlastic) + parseFloat(totalOtherMaterials)

    region = props.paramRegion;

    return (
        <>
            <Row className="equivalencia">
                <Col>
                    <div className='cardQuantity'>
                        <Card className='cardVolumeMaterial' style={{ width: '15rem', height: '8rem' }}>
                        <Card.Title className='title'>{props.paramRegion}</Card.Title>
                            <Card.Body className='quantityCard'>{Intl.NumberFormat().format(totalvolumeMaterial.toFixed(2))} <p className="ton">Ton</p> </Card.Body>
                        </Card>
                    </div>
                </Col>
                <Col>
                <img src={buss} alt="" />
                <h2>{Intl.NumberFormat().format(Math.round(totalvolumeMaterial / 15))} Ônibus</h2>
                <h6 text-my-light-gray>* 15 Toneladas cada</h6>
                </Col>
            </Row>            
        </>
    )
}

export function regionFlag(){
    return region;
}

export default CardVolumevolumeMaterials