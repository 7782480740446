import Col from "react-bootstrap/esm/Col"
import Row from "react-bootstrap/esm/Row"
import Card from "react-bootstrap/esm/Card"
import { MapBrazil } from "react-brazil-map"
import './invoice.css'
import { useState } from "react"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import SearchInvouce from "../../componentsLogged/invoice/searchRegionInvoice/searchRegionInvoice"
import { faComputerMouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import image10 from '../../assets/images/image10.png'
import image11 from '../../assets/images/image11.png'
import image12 from '../../assets/images/image12.png'


const InvoiceMap = ({ actualYear, year }) => {

    const [district, setDistrict] = useState('')
    const [invoice, setInvoice] = useState([])
    const [invoiceByState, setinvoiceByState] = useState([])

    let param = useParams()
    param = district

    const fetchUrl = async () => {
        try {
            const response = await fetch(`https://anuariodareciclagem.eco.br/app/yearbook/state/${param}`);
            setinvoiceByState(await response.json());
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetch('https://anuariodareciclagem.eco.br/app/yearbook')
            .then((response) => response.json())
            .then((json) => setInvoice(json))
            .catch(error => {
                throw (error)
            })
    }, [actualYear, year])

    useEffect(() => {
        fetchUrl()
    }, [param])

    const newYear = parseInt(actualYear)
    const newYear2 = parseInt(year)
    var entaire = 0
    const nationalTotal = invoice.filter(item => !newYear ? item.year === newYear2 : item.year === newYear).map((invoice) => invoice.total_revenue).filter(item => item !== null && item > 0)
    const sumTotal = nationalTotal.reduce((previousValue, CurrentValue) => parseFloat(previousValue) + parseFloat(CurrentValue), entaire)
    const totalByState = invoiceByState.filter(item => !newYear ? item.year === newYear2 : item.year === newYear).map((invoiceByState) => invoiceByState.total_revenue, invoiceByState.state).filter(item => item !== null && item > 0)
    const sumTotalByState = totalByState.reduce((previousValue, CurrentValue) => parseFloat(previousValue) + parseFloat(CurrentValue), entaire)
    const newArray = invoice.filter(item => item.total_revenue !== 0 && item.total_revenue !== null).map((invoice) => invoice.id)
    const percentage = invoice.length / invoice.length * 100

    return (
        <>
            <Row className='invoice'>
                <Col>
                    <img src={image10} alt="Retângulo 10 Anuário da Reciclagem" className='rectangle10'/>
                    <img src={image11} alt="Retângulo 11 Anuário da Reciclagem" className='rectangle11'/>
                    <img src={image12} alt="Retângulo 12 Anuário da Reciclagem" className='rectangle12'/>
                    <p className="totalInvoice">R$ {Intl.NumberFormat().format(sumTotal.toFixed(2))}</p>
                    <p className="invoiceText">é a soma do total faturado anualmente pelas<br />organizações mapeadas em território nacional</p>
                    <p className="invoiceText2">Selecione no mapa o estado que deseja ver o detalhamento do faturamento anual.</p>
                </Col>
                <div className="mapBox3">   
                    <MapBrazil colorLabel='#1a9587' bg='#ffffff' fill='#dddddd' colorStroke='#dddddd' width={69} height={100} preserveAspectRatio="none" backgroundSize='cover' onChange={setDistrict} PathMap />
                </div>
                <p className='textMap3'>
                    <FontAwesomeIcon icon={faComputerMouse} color='#ffffff' /> Selecione no mapa o estado que <br /> deseja para mais informações.
                </p>
                <p className="invoiceState">R$ {Intl.NumberFormat().format(sumTotalByState.toFixed(2))}</p>
                <img src={image12} alt="Retângulo 12 Anuário da Reciclagem" className='rectangle12Smaller'/>
                <p className="invoiceText3">Total faturado anualmente no estado {district}</p>
            </Row>
            {/*<Col className="cardsInvoice">
                <div className='cardInvoice1'>
                    <Card className='billingCard' style={{ width: '15rem', height: '7rem' }} sx={12}>
                        <Card.Title className='cardTitle'>Total Nacional</Card.Title>
                        <Card.Body className='quantityCardInvoice'>R$ {nationalTotal.length !== 0 && Intl.NumberFormat().format(sumTotal)}</Card.Body>
                    </Card>
                </div>
                <div className='cardInvoice2'>
                    <Card className='billingCard' style={{ width: '15rem', height: '7rem' }}>
                        <Card.Title className='cardTitle'>{district}</Card.Title>
                        <Card.Body className='quantityCardInvoice'>R$ {totalByState.length !== 0 && Intl.NumberFormat().format(sumTotalByState)}</Card.Body>
                    </Card>
                </div>
            </Col>
            <Col>
                <MapBrazil className='mapa' colorLabel='white' bg='#999999' fill='#49988A' colorStroke='#FFFFFF' onChange={setDistrict} />
                <p>
                    <FontAwesomeIcon icon={faComputerMouse} /> Clique em cima do estado que deseja para ver mais informações.
                </p>
            </Col>*/}
            {year && <SearchInvouce param={param} year={year} />}
        </>
    )
}

export default InvoiceMap