import Row from "react-bootstrap/esm/Row"
import './downloadYearbook.css';
import section6 from '../../assets/images/pdf-to-download-2.png'

const DownloadYearbook = () => {
    return(
        <>
        <Row className="yearbookDownload">
            <img src={section6}  alt="" className="section6" />
        </Row>
        </>
    )
}

export default DownloadYearbook