import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/Row'
import image13 from '../../assets/images/image13.png'
import React from 'react';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

import './linkDownload.css'

const LinkDownload = ({actualYear}) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [userForm, setUserForm] = useState({
        name: '',
        email: '',
        phone: '',
        institution: '',
        state: '',
        city: '',
        theme_relation: ''
    });

    const onSubmit = (data, e) => {
        e.preventDefault()
        try{
            fetch((`https://anuariodareciclagem.eco.br/app/form/send`),
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data)
                })
                console.log(JSON.stringify(data))
        } catch (error) {
            console.log(error);
        }
        setUserForm({
            name: '',
            email: '',
            phone: '',
            institution: '',
            state: '',
            city: '',
            theme_relation: ''
        });
        e.target.reset()
        if(actualYear == 2020) {
            window.open("https://uploads-ssl.webflow.com/609063d326f8d4cb6e852de0/61ae5f0d56587de8c41c970e_Anua%CC%81rio%20da%20Reciclagem%202020.pdf", "_blank")
        }
        else if(actualYear == 2021) {
            window.open("https://uploads-ssl.webflow.com/609063d326f8d4cb6e852de0/61cb6fc5ea9a1110f77558b3_Anu%C3%A1rio%20da%20Reciclagem%202021.pdf", "_blank")
        }
        else if(actualYear == 2022) {
            window.open("https://uploads-ssl.webflow.com/609063d326f8d4cb6e852de0/63ac4964a8bd71442db83ded_Anu%C3%A1rio%20da%20Reciclagem%202022.pdf", "_blank")
        }
        else if(actualYear == 2023) {
            window.open("https://drive.google.com/file/d/1nclGREjoOpyRjKSy4nyyA_F5EVaSCSVM/view", "_blank")
        }
    }

    return (
        <Row className="formDownload">
            <Col>
                <img src={image13} alt="Retângulo 13 Anuário da Reciclagem" className='rectangle13'/>
            </Col>
            <Col>
                <p className="textForm"> Para conferir mais<br />informações do Anuário {actualYear},<br />faça o cadastro para obter a<br />versão completa em PDF! </p>
            </Col>
            <form onSubmit={handleSubmit(onSubmit)} id="formanuario">
                <input type="text" placeholder="Nome Completo" name="name" className="formAnuario1" {...register("name", {required: true, maxLength: 80})}/>
                <input type="email" placeholder="Email" name="email" className="formAnuario2" {...register("email", {required: true, pattern: /^\S+@\S+$/i})}/>
                <input type="tel" placeholder="Telefone" name="phone" className="formAnuario3" {...register("phone", {required: true, maxLength: 12})}/>
                <select name="institution" className="formAnuario4" {...register("institution", { required: true })}>
                    <option disabled="disabled" selected="selected">Qual o seu segmento de atuação?</option>
                    <option value="Cooperativa">Cooperativa</option>
                    <option value="Empresa">Empresa</option>
                    <option value="Governo">Governo</option>
                    <option value="Imprensa">Imprensa</option>
                    <option value="Terceiro Setor">Terceiro Setor</option>
                    <option value="Universidade">Universidade</option>
                </select>
                <select name="state" className="formAnuario5" {...register("state", { required: true })}>
                    <option disabled="disabled" selected="selected">Qual o seu estado?</option>
                    <option value="AC">AC</option>
                    <option value="AL">AL</option>
                    <option value="AP">AP</option>
                    <option value="AM">AM</option>
                    <option value="BA">BA</option>
                    <option value="CE">CE</option>
                    <option value="DF">DF</option>
                    <option value="ES">ES</option>
                    <option value="GO">GO</option>
                    <option value="MA">MA</option>
                    <option value="MT">MT</option>
                    <option value="MS">MS</option>
                    <option value="MG">MG</option>
                    <option value="PA">PA</option>
                    <option value="PB">PB</option>
                    <option value="PR">PR</option>
                    <option value="PE">PE</option>
                    <option value="PI">PI</option>
                    <option value="RJ">RJ</option>
                    <option value="RN">RN</option>
                    <option value="RS">RS</option>
                    <option value="RO">RO</option>
                    <option value="RR">RR</option>
                    <option value="SC">SC</option>
                    <option value="SP">SP</option>
                    <option value="SE">SE</option>
                    <option value="TO">TO</option>
                </select>
                <input type="text" placeholder="Cidade" name="city" className="formAnuario6" {...register("city", {required: true, maxLength: 80})}/>
                <input type="text" placeholder="Qual a sua relação com o tema?" name="theme_relation" className="formAnuario7" {...register("theme_relation", {required: true, maxLength: 200})}/>
                <button type="submit" value="Submit" form="formanuario" className="formAnuario8">Enviar</button>
            </form>
            {/*<Col className='downloadLink'>
                <p className="app"> O Anuário da Reciclagem {actualYear} está disponível também em versão PDF </p>
                <p className="linkDownload"> Clique {
                    actualYear == 2020 &&
                    <a href="https://uploads-ssl.webflow.com/609063d326f8d4cb6e852de0/61ae5f0d56587de8c41c970e_Anua%CC%81rio%20da%20Reciclagem%202020.pdf" className='link'>aqui</a> 
                    || actualYear == 2021 &&
                    <a href="https://uploads-ssl.webflow.com/609063d326f8d4cb6e852de0/61cb6fc5ea9a1110f77558b3_Anu%C3%A1rio%20da%20Reciclagem%202021.pdf" className='link'>aqui</a> 
                    || actualYear == 2022 && 
                    <a href="https://uploads-ssl.webflow.com/609063d326f8d4cb6e852de0/63ac4964a8bd71442db83ded_Anu%C3%A1rio%20da%20Reciclagem%202022.pdf" className='link'>aqui</a>
                    || actualYear == 2023 && 
                    <a href="https://uploads-ssl.webflow.com/609063d326f8d4cb6e852de0/63ac4964a8bd71442db83ded_Anu%C3%A1rio%20da%20Reciclagem%202022.pdf" className='link'>aqui</a> 
                    } para acompanhar! </p>
            </Col>*/}
        </Row>
    )
}

export default LinkDownload