import './Home.css'
import YearBook from '../components/yearbook/yearbook';

const Home = () => {
    return (
        <>
            <YearBook/>    
        </>
    )
}

export default Home