import React, { useState } from 'react';
import Button from "react-bootstrap/esm/Button"
import Col from "react-bootstrap/esm/Col"
import Row from "react-bootstrap/esm/Row"
import Form from 'react-bootstrap/Form'
import { Link, redirect } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';


const PasswordChange = ({ email }) => {

    const [campos, setCampos] = useState({
        email: email,
        password: '',
    })

    function handleInputChange(e) {
        campos[e.target.name] = e.target.value;
        setCampos(campos);
    }

    function clearInputs() {
        setCampos({
            email: '',
            password: '',
        })
    }

    function handleClick() {
        fetch(('https://anuariodareciclagem.eco.br/app/user/update'),
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(campos),
            })
            .then((response) => response.json())
            .then((json) => setCampos(json))
            .catch(err => console.log(err));
    }

    function handleFormSubmit(event) {
        event.preventDefault();
        handleClick();
        clearInputs()
        handleShow()
    }

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true);

    return (
        <>
            <Alert variant="success" show={show} >
                Senha alterada com sucesso!{" "}
                <Link to="/" className='alert-link' onChange={clearInputs}>Voltar</Link>
            </Alert>
            <Row>
                <Col>
                    <Form id='formChangePassword' className="pt-5">
                        <Form.Group className="mb-3">
                            <Form.Control type='text' id="disabledTextInput" placeholder="Insira seu e-mail" name='email' onChange={handleInputChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control type='password' placeholder="Insira uma nova senha" name='password' onChange={handleInputChange} required />
                        </Form.Group>
                        <Button className='formButtonChangePassword' variant="primary" type="button" onClick={handleFormSubmit}>
                            Enviar
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    )
};

export default PasswordChange;