import { useEffect } from 'react';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { getFlag } from '../../components/cooperative/cooperative2';
import './seacrhStateCooperative.css'
import { faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Search = ({year}) => {

    const [itemSearch, setItemSearch] = useState('')

    function HandleChange(e) {
        setItemSearch(e.target.value)
    }

    const [result, setResult] = useState([])

    useEffect(() => {
        fetch(`https://anuariodareciclagem.eco.br/app/cooperative/city/${getFlag() || "SP" }/${year}`)
            .then((response) => response.json())
            .then((json) => setResult(json))
            .catch((error) => console.log(error))
    }, [itemSearch])

    const filtered = result.filter(item => item !== undefined && item.name === itemSearch).map((result) => result.name)

    return (
        <>
            <div className="search">
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                    <Form.Control aria-describedby="basic-addon2" placeholder='Digita a cidade' onChange={HandleChange} />
                </InputGroup>
                {itemSearch && <h4 text-center>A Cidade de {filtered[0]} possui {filtered.length} cooperativas</h4>}
            </div>
        </>
    )
}

export default Search