import { useEffect } from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useParams } from 'react-router-dom';
import CardActiveCollectorsLogged from '../../../componentsLogged/active_collectors/activeCollectors';
import CardActiveCollectors from '../../active_collectors/card_active_collectors';
//import CardVolumeMaterials from '../../volume_materials/card_volume_material';

import './buttonRegion.css'

const ButtonRegion = (props) => {

    const [state, setState] = useState([])
    const [param, setParam] = useState('')

    let paramParam = useParams()

    useEffect(() => {
        fetch(('https://anuariodareciclagem.eco.br/app/city'),
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            })
            .then((response) => response.json())
            .then((json) => setState(json))
    }, [])

    const unicState = state.map((state) => state.state).sort()

    function unique(value, index, self) {
        return self.indexOf(value) === index;
    }

    const singleStates = unicState.filter(unique).sort((a, b) => a.unicState > b.unicState ? -1 : 1)

    const [showResults, setShowResults] = useState(false)

    const HandleClick = (e) => {
        setParam(e.target.value)
        paramParam = param
        setShowResults(true)
    };
    
    return (
        <>
            <div className="buttonGroup">
                {/*{singleStates.map((singleState, index) =>
                    <ButtonGroup aria-label="Basic example" key={index}>
                        <Button className="button" variant="secondary"                            
                            value={singleState}
                            onClick={HandleClick}>{singleState}</Button>
                    </ButtonGroup>
                )}*/}
                <CardActiveCollectors param={param} regionTotal2={props.regionTotal} stateCollectors2={props.stateCollectors} numberCollectors2={props.numberCollectors} state3={props.state2} year={props.year}/>
                {<CardActiveCollectorsLogged param={param} year={props.year}/>}
            </div>
        </>
    )
}

export default ButtonRegion