import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import logo from "../../../frontend/src/assets/images/Logo.png"
import FormToFill from "../components/form/form"


const Registration = () => {
    return (
        <>
        <Row className="yearbook" xs={12}>
                <Col>
                    <img src={logo} alt="" width="360" height="185" className="logo"/>
                </Col>
                <Col>
                    <div className="introduction">
                        <p>
                            O Anuário da Reciclagem é o maior repositório digital de informações sobre catadores e catadoras e seu papel na cadeia da reciclagem no Brasil.
                            Entenda a importância e o papel desses atores nesse seguimento econômico estratégico para a sustentabilidade do planeta,
                            navegando em uma importante ferramenta de análise da gestão de resíduos sólidos no país.
                        </p>
                    </div>
                </Col>
            </Row>  
            <FormToFill/>
        </>
    )
}

export default Registration