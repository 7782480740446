import { useLocation } from "react-router-dom"
import PasswordChange from "../components/login/passwordChange"

const ChangePassword = () => {
    const location = useLocation()
    const {from} = location.state
    return (
        <>
            <PasswordChange email={from}/>
        </>
    )
}
export default ChangePassword