import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { useEffect, useState } from 'react';
import './cooperative.css'
import { actualYear, countFlag } from '../yearbook/yearbook';
import image4 from '../../assets/images/image4.png'

let conditionalFlag = 0;

const Cooperative = (props) => {

    const [cooperative, setCooperative] = useState([]);
    let count = countFlag;
    
    const otherFetch = async () => {
        try {
            const response = await fetch((`https://anuariodareciclagem.eco.br/app/cooperative/year/${actualYear() || props.yearLogged}`),
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                });
            setCooperative(await response.json());
            conditionalFlag = 2
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        otherFetch()
    }, [count, actualYear(), props.yearLogged])

    function yearNumber(){
        if (conditionalFlag === 1){
            return Intl.NumberFormat().format(props.number.length)
        } else {
            return Intl.NumberFormat().format(cooperative.length)
        }
    }

    const newArray = cooperative.map((cooperative) => cooperative.id).filter(item => item !== 0 && item !== null)
    const percentage = newArray.length / cooperative.length * 100

    return (
        <>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
            <Row className='totalcooperativa'>
                <Col>
                    <img src={image4} alt="Retângulo 4 Anuário da Reciclagem" className='rectangle4' />
                    <div className='organization'>
                        Panorama das organizações<br />
                            de catadores<br/>
                            {/* <h6>Amostragem: Referente a {newArray.length} cooperativas analisadas ({percentage.toFixed(2)}%)</h6> */}
                        {/*<h2>{yearNumber()}</h2>*/}
                    </div>
                    <p className='p2'>
                        {Intl.NumberFormat().format(newArray.length)}
                    </p>
                </Col>
            </Row>
        </>
    )
}

export function getConditionalFlag() {
    conditionalFlag = 1;
    return conditionalFlag;
}

export default Cooperative;