import Row from 'react-bootstrap/esm/Row'
import Card from 'react-bootstrap/esm/Card'
import './avaregeIncome.css'
import { useState } from 'react'
import { useEffect } from 'react'

const CardAvarege = ({ param, actualYear, year }) => {
    const newActualYear = parseInt(actualYear)
    const newActualYear2 = parseInt(year)

    const [avaregeRegion, setAvaregeRegion] = useState([])

    useEffect(() => {
        fetch(`https://anuariodareciclagem.eco.br/app/yearbook/region/${param}`)
            .then((response) => response.json())
            .then((json) => setAvaregeRegion(json))
            .catch((err) => console.log(err))
    }, [param, actualYear])

    const averageMonthCollectorsRegion = avaregeRegion
        .filter(item => !newActualYear ? item.year === newActualYear2 : item.year === newActualYear)
        .map((averageMonthly) => averageMonthly.average_monthly_income)
        .filter(item => item > 0 && item !== null)

    const averageMonthRegion = avaregeRegion
        .filter(item => !newActualYear ? item.year === newActualYear2 : item.year === newActualYear)
        .map((averageMonthly) => averageMonthly.average_monthly_income)
        .filter(item => item > 0 && item !== null)
        .reduce((previousValue, currValue) => parseFloat(previousValue) + parseFloat(currValue), 0)

    const operation = averageMonthRegion / averageMonthCollectorsRegion.length

    function FiltraNaN(value) {
        return !Number.isNaN(value[4])
    }

    const newArrayAverege = avaregeRegion
        .filter(item => !newActualYear ? item.year === newActualYear2 : item.year === newActualYear)
        .map((avaregeRegion) => [avaregeRegion.cooperative_id, avaregeRegion.state, avaregeRegion.region, avaregeRegion.name, parseFloat(avaregeRegion.average_monthly_income)])
        .filter(FiltraNaN)

    let objArray = []

    newArrayAverege.forEach(element => {
        let obj = {}
        obj.cooperative_id = element[0]
        obj.state = element[1]
        obj.region = element[2]
        obj.name = element[3]
        obj.avarege = element[4]

        objArray.push(obj)
        return element
    })

    const groups = objArray.reduce((acc, curr) => {
        if (!acc[curr.state]) {
            acc[curr.state] = {
                ...curr
            }
            acc[curr.state].name = 1
            acc[curr.state].avarege = 0
        }
        else if (acc[curr.state].avarege > 0 && curr.avarege > 0) {
            acc[curr.state].name = acc[curr.state].name + 1
        }
        else if (newActualYear == 2020 && (curr.state == 'GO' || curr.state == 'PI' || curr.state == 'RN' || curr.state == 'AC' || curr.state == 'AP' || curr.state == 'RR' || curr.state == 'TO')) {
            acc[curr.state].name = 1
        }
        else if (newActualYear == 2021 && (curr.state == 'PI' || curr.state == 'AC' || curr.state == 'AP' || curr.state == 'RR')) {
            acc[curr.state].name = 1
        }
        else if (newActualYear == 2022 && (curr.state == 'PB' || curr.state == 'RN' || curr.state == 'AC' || curr.state == 'AP')) {
            acc[curr.state].name = 1
        }

        if (newActualYear == 2020 && (curr.state == 'AP' || curr.state == 'RR')) {
            acc[curr.state].avarege = 745.84
            acc[curr.state].name = 1
        }
        else if (newActualYear == 2021 && curr.state == 'AP') {
            acc[curr.state].avarege = 500.00
            acc[curr.state].name = 1
        }
        acc[curr.state].avarege = parseFloat(acc[curr.state].avarege) + parseFloat(curr.avarege)
        return acc
    }, {})

    const newList = Object.values(groups).sort((a, b) => a.state > b.state ? 1 : -1)

    return (
        <>
            <Row className='cardGroupeAvarege'>
                <Card className='cardavAregeIndex' style={{ width: '13rem', height: '15rem',  }}>
                    <Card.Body className='bodyCard'>
                        <Card.Text className='cardText'>
                            Média Região
                            R$ {averageMonthCollectorsRegion.length !== 0 ? operation.toFixed(2).replace(".", ",") : " "}
                        </Card.Text>
                    </Card.Body>
                </Card>
                {newList.map((newList, index) =>
                    newList.length !== 0 &&
                    <Card className='cardAvarege' style={{ width: '13rem', height: '15rem' }} key={index}>
                        <Card.Title className='avaregeTitle'>{newList.state}</Card.Title>
                        <Card.Body className='bodyCard'>
                            <Card.Text className='cardText'>
                               {`R$ ${Intl.NumberFormat().format((newList.avarege / newList.name).toFixed(2))}`}
                            </Card.Text>
                        </Card.Body>    
                    </Card>
                )}
            </Row>
        </>
    )
}

export default CardAvarege