import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Form from "react-bootstrap/esm/Form";
import { useEffect, useState } from "react";
import "./yearBook.css"
import Cooperative2, { getFlag } from "../cooperative/cooperative2";
import image1 from '../../assets/images/image1.png'
import image2 from '../../assets/images/image2.png'
import image3 from '../../assets/images/image3.png'
import { getRegion } from "../active_collectors/card_active_collectors";
import Cooperative, { getConditionalFlag } from "../cooperative/cooperative";
import InvoiceMap from "../invoice/invoiceMap";
import Agreement from "../agreement/agreement";
import CommercializedMaterial from "../commercialized_material/commercialized_material";
import VolumeMaterials from "../volume_materials/volume_material";
import LinkDownload from "../linkDownload/linkDownload";
import AvaregeIncome from "../average_monthly_income/avaregeIncome";
import FormToFill from '../form/form';
import { getState } from "../volume_materials/card_meterial";
import { regionFlag } from "../volume_materials/card_volume_material";

let yearSelected = 0;
export let countFlag = 0;

const YearBook = () => {

    const [yearbook, setYearbook] = useState([]);
    const [cooperativeState, setCooperativeState] = useState([]);
    const [cooperative, setCooperative] = useState([]);
    const [collectorsRegion, setCollectorRegion] = useState([]);
    const [collectorsState, setCollectorState] = useState([]);
    const [material, setMaterial] = useState([]);
    const [volumeMaterial, setVolumevolumeMaterial] = useState([]);

    const fetchState = async () => {
        try {
            const response = await fetch((`https://anuariodareciclagem.eco.br/app/cooperative/state/${getFlag()}/${actualYear()}`),
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                });
            setCooperativeState(await response.json());
            countFlag = countFlag + 1;
        } catch (error) {
            console.log(error);
        }
    };

    const fetchYear = async () => {
        try {
            getConditionalFlag();
            const response = await fetch((`https://anuariodareciclagem.eco.br/app/cooperative/year/${actualYear()}`),
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                });
            setCooperative(await response.json());
            countFlag = countFlag + 1;
        } catch (error) {
            console.log(error);
        }
    };

    const fetchCollectors = async () => {
        try{
            fetch((`https://anuariodareciclagem.eco.br/app/activecollectors/region/${getRegion()}/${actualYear()}`),
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                })
                .then((response) => response.json())
                .then((json) => setCollectorRegion(json))
        } catch (error) {
            console.log('Failed to fetch from Amazon DB', error);
        }
    }

    const fetchCollectorsState = async () => {
        try{
            fetch((`https://anuariodareciclagem.eco.br/app/activecollectors/state/${getState()}/${actualYear()}`),
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                })
                .then((response) => response.json())
                .then((json) => setCollectorState(json))
        } catch (error) {
            console.log('Failed to fetch from Amazon DB', error);
        }
    }

    const fecthMaterial = () => {
        fetch((`https://anuariodareciclagem.eco.br/app/commercialized_Material/state/${getState()}/${actualYear()}`),
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            })
            .then((response) => response.json())
            .then((json) => setMaterial(json))
            .catch(err => { console.log(err) })
    }

    const fecthVolumeMaterial = () => {
        fetch((`https://anuariodareciclagem.eco.br/app/commercialized_Material/region/${regionFlag()}/${actualYear()}`),
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            })
            .then((response) => response.json())
            .then((json) => setVolumevolumeMaterial(json))
            .catch(err => { console.log(err) })
    }

    useEffect(() => {
        fetch(('https://anuariodareciclagem.eco.br/app/yearbook'),
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }
        )
            .then((response) => response.json())
            .then((json) => setYearbook(json))
    }, []);

    
    var years = yearbook.map(yearbook => yearbook.year)
    
    function unique(value, index, self) {
        return self.indexOf(value) === index;
    }
    
    var uniqueyears = years.filter(unique).sort(function(a, b){return b-a});

    if(yearSelected === 0 || yearSelected === undefined){
        yearSelected = uniqueyears[0];  
    }

    function onChange (e) {
        yearSelected = e.target.value;
        if (getFlag() !== ''){
            fetchState();
            fetchCollectors();
            fetchCollectorsState();
            fecthMaterial();
            fecthVolumeMaterial();
        }else{
            fetchYear();
            fetchCollectors();
            fetchCollectorsState();
            fecthMaterial();
            fecthVolumeMaterial();
        }
        return actualYear();
    }

    var entire = 0

    const groups = collectorsRegion.reduce((collectos, current, entire) => {
        if (!collectos[current.state]) {
            collectos[current.state] = {
                ...current
            }   
            collectos[current.state].man = 0;
        }

        collectos[current.state].man = collectos[current.state].man + current.man + current.woman

        return collectos
    }, {})

    const newList = Object.values(groups)

    const sumCollectors = newList
        .map((newList) => [newList.man, newList.woman])
        .reduce((man, woman) => parseFloat(man) + parseFloat(woman), entire)

    return(
        <>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
            <Row className="yearbook">
                <Col>
                    <img src={image1} alt="Anuário da Reciclagem" className="rectangle"/>
                    <div className="text-wrapper">Anuário da <br/>Reciclagem<br/>{actualYear()}</div>
                    <img src={image2} alt="Retângulo 2 Anuário da Reciclagem" className="rectangle2"/>
                </Col>
                <Col>
                    <div>
                        <div className="box">O Anuário da Reciclagem é o maior repositório digital de informações sobre catadores e catadoras e seu papel na cadeia da reciclagem no Brasil.<br/><br/>Entenda a importância e o papel desses atores nesse segmento econômico estratégico para a sustentabilidade do planeta, navegando em uma importante ferramenta de análise da gestão de resíduos sólidos no país.</div>
                        <img src={image3} alt="Retângulo 3 Anuário da Reciclagem" className="rectangle3"/>
                    </div>
                </Col>
                <Col>
                    <div>
                        <Form.Group controlId="formPlaintextYear">
                            <Form.Label className='select'>
                                Selecione o ano
                            </Form.Label>
                            <Col>
                                <Form.Control type="submit" as="select" className="selectYear" aria-label="Default select" size='sm' onChange={onChange}>
                                    {uniqueyears.map((uniqueyear, index) => (
                                        <option key={index} >{uniqueyear}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </div>
                </Col>
                <Col>
                    <div className="cooperative">
                        <Cooperative className='cooperative' number={cooperative} yearSelected={yearSelected}/>
                    </div>
                    <Cooperative2 className='cooperative2' year={cooperativeState} />
                </Col>
                <Col>
                    <div>
                        <CommercializedMaterial />
                    </div>
                </Col>
                <Col>
                    <div>
                        <InvoiceMap actualYear={actualYear()}/>
                    </div>
                </Col>
                <Col>
                    <div>
                        <AvaregeIncome actualYear={actualYear()}/>
                    </div>
                </Col>
                <Col>
                    <div>
                        <LinkDownload actualYear={actualYear()}/> 
                    </div>
                </Col>
            </Row>
            {/* <VolumeMaterials actualYear={actualYear()}/>
            <Agreement actualYear={actualYear()}/>
            <FormToFill/> */}
        </>
    )
}

export function actualYear(){
    return yearSelected
}

export default YearBook